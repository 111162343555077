export const Contact = (props) => {
  return (
    <div id='contact'>
      <div className='container'>
        <div className='section-title'>
          <h2 className='text-center'>Contact</h2>
          <p className='text-center'>
            I teach in Aurora, Colorado. Give me a call at <a href="tel:3037512983"><strong>(303) 751-2983</strong></a> to get started.
          </p>
        </div>
      </div>
    </div>
  )
}
