export const Rates = (props) => {
  return (
    <div id='rates'>
      <div className='container'>
        <div className='section-title'>
          <h2 className='text-center'>Rates</h2>
          <h3 className='text-center'>Individual Lesson Rates:</h3>

          <p><strong>Piano Lessons</strong><br />
            1/2 hr., once a week @ $120/month OR 1 hr., once a week @ $210/month</p>
          <p><strong>Voice Lessons</strong><br />
            1/2 hr., once a week @ $130/month OR 1 hr., once a week @ $220/month</p>
          <p><strong>BOTH Piano &amp; Voice Lessons</strong><br />
            1 hr. once a week @ $230/month</p>
          <p><small>Note: Discounted rates for multiple children in household will be determined on a case-by-case basis.</small></p>

          <br /><br />
          <h3 className='text-center'>Accompaniment &amp; Performance Rates:</h3>

          <p>Please <a href='#contact'>give me a call</a> to discuss rates if you need me to accompany you for a rehearsal/audition/performance or if you are looking for a pianist or vocal soloist for a wedding, memorial service or cocktail event. Rates will be customized to fit your needs.</p>

          <br /><br />

          <p className='text-center'><strong>Also, I offer a $25 cash “Thank You” when you refer a new student to me!</strong></p>
        </div>
      </div>
    </div>
  )
}
