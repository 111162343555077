import { Navigation } from './components/navigation'
import { Header } from './components/header'
import { About } from './components/about'
import { Rates } from './components/rates'
import { Contact } from './components/contact'
import SmoothScroll from 'smooth-scroll'
import { Agreement } from './components/agreement'
import './App.css'

export const scroll = new SmoothScroll('a[href*="#"]', {
  speed: 1000,
  speedAsDuration: true,
})

const App = () => {
  return (
    <div>
      <Navigation />
      <Header />
      <About />
      <Rates />
      <Agreement />
      <Contact />
    </div>
  )
}

export default App
