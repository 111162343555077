// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
var ___CSS_LOADER_URL_IMPORT_0___ = new URL("piano-background.jpg", import.meta.url);
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".piano-background {\n  background-image:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ");\n  /* TODO: FIX SIZING FOR ALL VIEWPORTS */\n  background-size:cover;\n}\n\n/* a little bit of non-semantic emphasis for text spans */\n.highlight {\n  font-weight: 600;\n  /* border-bottom:solid 1px #CCC; */\n}", "",{"version":3,"sources":["webpack://./src/App.css"],"names":[],"mappings":"AAAA;EACE,wDAA4C;EAC5C,uCAAuC;EACvC,qBAAqB;AACvB;;AAEA,yDAAyD;AACzD;EACE,gBAAgB;EAChB,kCAAkC;AACpC","sourcesContent":[".piano-background {\n  background-image:url('piano-background.jpg');\n  /* TODO: FIX SIZING FOR ALL VIEWPORTS */\n  background-size:cover;\n}\n\n/* a little bit of non-semantic emphasis for text spans */\n.highlight {\n  font-weight: 600;\n  /* border-bottom:solid 1px #CCC; */\n}"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
