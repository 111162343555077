import PdfAgree from '../AGREEMENT.pdf';
import PdfInfo from '../FileInfoForm.pdf';

export const Agreement = (props) => {
  return (
    <div id='agreement'>
      <div className='container'>
        <div className='section-title'>
          <h2 className='text-center'>Agreement</h2>
          <p>
            In order to be a piano or voice student of mine, the following policies must be agreed to before starting lessons: <a href={PdfAgree} target="_blank" rel="noreferrer">Agreement Form (PDF)</a>
          </p>
          <p>
            Also, before you start, I need the following information: <a href={PdfInfo} target="_blank" rel="noreferrer">Information Form (PDF)</a>
          </p>
        </div>
      </div>
    </div>
  )
}
