export const About = (props) => {
  return (
    <div id='about'>
      <div className='container'>
        <div className='row'>
          <div className='col-xs-12 col-md-6'>
            <img src='img/kathyphoto.jpg' className='img-responsive' alt='A portrait of Kathy' />
          </div>
          <div className='col-xs-12 col-md-6'>
            <div className='about-text'>
              <h2>About Me</h2>
              <p><span className='highlight'>Hi there!</span> My name is Kathy Welch... “Ms. Kathy” to my students. I’ve been a full-time Piano &amp; Voice teacher in my Aurora home for the past 27+ years, with 41 years total teaching experience. And my passion for teaching Piano &amp; Voice lessons has only continued to grow thru the years!</p>

              <p>I <span className='highlight'>LOVE</span> working with young people to build confidence, skills and techniques, such as theory, ear-training, sight-reading and a solid foundation of musical knowledge. Learn from a positive, passionate &amp; <span className='highlight'>FUN</span> teacher with lots of praise for students and a great sense of humor!!</p>

              <p>I also coach theatre for audition and performance prep, including choosing monologues, memorization, delivery, stage-presence, preparing songs for musical auditions, accompaniment and confidence-building for performance. Be ready for that audition or show!</p>

              <p>Excellent references can be provided.</p>

              <p>I grew up in a musical home, so my passion for music is deeply-rooted and lifelong. My mother started teaching me piano at the age of 4 and wrote my first song for me, which I performed on Easter Sunday at 4 years old. She also inspired my love of theatre and acting. I am classically educated and perform professionally. I’ve directed children’s choirs, acted in plays &amp; musicals, sung as a soloist &amp; in choirs, played cocktail piano for a number of years and have accompanied individual soloists &amp; choirs thruout my career.</p>

              <p><a href="#contact-block">Call <span className='highlight'>NOW</span></a> for a <span className='highlight'>FREE</span> consultation and get started <span className='highlight'>TODAY</span></p>

              <p><small>Thanks so much for your time &amp; consideration!</small></p>

              {/* It's pointless to pull this from .json, so comment out: */}
              {/* <p>{props.data ? props.data.paragraph : 'loading...'}</p> */}
              {/* <h3>Why Choose Us?</h3> */}
              {/* <div className='list-style'>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why.map((d, i) => (
                          <li key={`${d}-${i}`}>{d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
                <div className='col-lg-6 col-sm-6 col-xs-12'>
                  <ul>
                    {props.data
                      ? props.data.Why2.map((d, i) => (
                          <li key={`${d}-${i}`}> {d}</li>
                        ))
                      : 'loading'}
                  </ul>
                </div>
              </div> */}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
